import Img from 'gatsby-image';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import { DefaultLayout } from '../../components/layout';
import { CaseBlock, Facts, Stage } from '../../components/molecules';

const jassBotQuery = graphql`
  query jassBotQuery {
    allStagesJson(filter: { siteTitle: { eq: "Jass-Bot" } }) {
      edges {
        node {
          id
          siteTitle
          siteDescription
          title
          contentBlocks {
            id
            value
          }
          imageSrc {
            childImageSharp {
              fluid(maxWidth: 800, quality: 92) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          imageAlt
        }
      }
    }
    allImageSharp(filter: { fluid: { originalName: { regex: "/(bot|school_meetup|meetup)/" } } }) {
      edges {
        node {
          id
          fluid(maxWidth: 800, quality: 92) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

const JassBotCase = () => (
  <StaticQuery
    query={jassBotQuery}
    render={({ allStagesJson, allImageSharp }) => {
      const stageData = allStagesJson.edges[0].node;
      const robotImage = allImageSharp.edges[2].node.fluid;
      const networkImg = allImageSharp.edges[1].node.fluid;
      const cardImg = allImageSharp.edges[3].node.fluid;

      return (
        <DefaultLayout>
          <Stage
            modifiers={['gradient', 'case']}
            image={{
              fluid: stageData.imageSrc.childImageSharp.fluid,
              alt: stageData.imageAlt,
            }}
            title={<h1 dangerouslySetInnerHTML={{ __html: stageData.title }} />}
          >
            <div>
              {stageData.contentBlocks.map((block) => (
                <p key={block.id} dangerouslySetInnerHTML={{ __html: block.value }} />
              ))}
            </div>
          </Stage>

          <CaseBlock
            title="Reinforcement learning"
            subtitle="Bot lernt ohne menschliches Einwirken"
            graphic={<Img fluid={robotImage} alt="Bild eines Roboters" />}
          >
            <p>
              Der Jassbot erlernt eine Strategie, ohne dass ein Mensch im Spiel mitwirkt, mit einer Technologie namens
              Reinforcement learning (zu deutsch etwa, &quot;bestärkendes Lernen&quot;). Dem Bot wird weder gesagt, ob er
              sich in einer guten Ausgangslage befindet, weder trainiert er anhand von vorgefertigten Spielerhandlungen. Per
              Definition könnte ein solcher Bot schliesslich nie besser werden, als der menschliche Spieler, der ihm das
              Spiel beigebracht hat. Der Bot spielt gegen sich selbst. Er erlernt sein Verhalten im Spiel mithilfe der
              jeweils erhaltenen &quot;Belohnungen&quot; in Form von Spielpunkten. Wie gut - oder eben schlecht - er gespielt
              hat, kann er nur auf diese Weise erschliessen. Mit dieser Technik kann der Bot lernen, wie er am besten in
              seiner Umgebung interagiert, ohne dass ihm dies von einem Menschen gezeigt wurde (wie es etwa beim sog.
              überwachtem Lernen der Fall wäre).
            </p>
          </CaseBlock>

          <CaseBlock
            title="Deep learning"
            subtitle="Künstliche neuronale Netze"
            graphic={<Img fluid={networkImg} alt="Deep learning" />}
            isReverse
          >
            <p>
              Der Bot trainiert ein sog. neuronales Netwerk. Die Architektur basiert grob auf unserem Verständnis des
              menschlichen Gehirns. Ein neuronales Netzwerk besteht aus vielen Zahlen (auch Parameter genannt). Der Input
              wird mit den Parametern multipliziert und addiert, bis am Schluss ein Endwert herauskommt. Mithilfe der
              erreichten Punktzahl im Spiel kann das Netzwerk die Parameter anpassen und so beim nächsten Mal einen Endwert
              herausgeben, der eher dem wahren Wert entspricht.
            </p>
          </CaseBlock>

          <CaseBlock
            title="Jass, ein imperfect information Spiel"
            subtitle="Fokus aufs Wesentliche"
            graphic={<Img fluid={cardImg} alt="Jasskarte" />}
          >
            <p>
              Einen Bot auf das Spiel Jass zu trainieren ist sehr schwierig, da bei diesem Spiel nicht allen Spielern immer
              alle Informationen über den Gesamtzustand des Spiels bekannt sind. Es handelt sich um ein sogenanntes
              &quot;imperfect information game&quot;. Zudem gibt es unzählige Kombinationen von möglichen Handkarten. Es ist
              daher nicht möglich, dass der Bot für jede mögliche Kombination eine passende Taktik erlernt. Der Bot muss
              verschiedene Spielhände selber erkennen und zuordnen können.
            </p>
          </CaseBlock>
          <div className="container">
            <Facts title="Technologie">
              <ul>
                <li>Geschrieben in Python</li>
                <li>Umsetzung mit Keras, Tensorflow und Numpy</li>
                <li>Continuous Integration mit GitLab CI</li>
                <li>Lokales Training auf DeepReturn GPUs</li>
              </ul>
            </Facts>
          </div>
        </DefaultLayout>
      );
    }}
  />
);

export default JassBotCase;
